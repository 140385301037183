.votacao{
	overflow-x: hidden;
}
.categorias-subtitulo{
	text-align: center;
}
.trabalhos{
	width: 70%;
	margin: 0 15% 4vw 15%;
	padding: 1vw;
	display: flex;	
	flex-wrap: wrap;
	background-color: #3f5b70;
	border-radius: 1vw;
}
.trabalho-item{
	background-color: #ff8100;
	flex: 1 0 30%;
	border-radius: 0.5vw;
	margin: 0.5vw;
	text-align: center;
	color: #fff;
}
.trabalho-item-titulo{
	font-size: 1.1vw;
	margin-top: 0.5vw;
}
.trabalho-item-btn{
	font-size: 1.1vw;
	background-color: blue;
	line-height: 2vw;
	border-radius: 2vw;
	width: 90%;
	margin-left: 5%;
	margin-bottom: 1vw;
	margin-top: 2vw;
}
.botao-laranja{
	text-align: center;
	margin-bottom: 2vw;
	color: #fff;
}
.trabalho-item-selecionado{
	background-color: #ffa500;
	border: 1px solid #fff;
}
.trabalho-item-selecionado .trabalho-item-btn{
	background-color: #ffcb00;
}

/*===============================*/
/*=============MOBILE============*/
/*===============================*/

@media only screen and (max-width: 992px){
	.trabalho-item{
		flex: 1 0 90%;
		border-radius: 1vw;
		margin: 1vw;
	}
	.trabalho-item-titulo{
		font-size: 4vw;
		margin-top: 1vw;
	}
	.trabalho-item-btn{
		font-size: 3vw;
		line-height: 5vw;
		border-radius: 2vw;
		margin-bottom: 2vw;
		margin-top: 4vw;
	}
}