.espaco-avaliador-active{
	background: linear-gradient( 0deg, #fff 80%, #ff681a 80% 20%)!important;
	
	color: #ff681a;
}
.espaco-avaliador-titulo{
	font-size: 1.1rem;
	text-align: center;
	padding: 32px 16px;
	color: #103148;
}
.espaco-avaliador-texto{
	text-align: center;	
	padding: 1rem 20%;
}
.trabalhos{
	background-color: #e1e1e1;
}
.trabalho-item {
	background-color: #cccccc;
	color: #3f3f3f;
}
.trabalho-item-btn {
	background-color: #ffcb00;
	color: white;
	margin-top: 1vw;
}
.trabalho-item-btn-avaliar {
	background-color: transparent;
	border: 3px solid #6f818f;
	background-color: #6f818f ;
	margin-top: 1vw;
	color: white;
}
.trabalho-item-selecionado{
	background-color: #ffcb00;

	border: 3px solid #fff;
}
.trabalho-item-selecionado .trabalho-item-btn{
	background-color: #fff;

	color: #ffcb00;
}
.trabalho-item-selecionado .trabalho-item-btn-avaliar{
	background-color: transparent;

	border: 3px solid #fff;

	color: #fff;
}