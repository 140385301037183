.inscrevase {
	overflow-x: hidden;
}

.container-inscrevase {
	background-color: #e1e1e1;
	border-radius: 1rem;
	/*margin-top: 1.5rem;*/
	display: flex;
	flex-direction: column;
	padding: 5vw 8vw;
	align-items: center;
}

.chamada-informacoes {
	margin-left: 5%;
	width: 90%;
	text-align: center;
}

	.chamada-informacoes:nth-of-type(2) {
		margin: 1rem 5%;
	}

	.chamada-informacoes h3 {
		font-size: 1.4rem;
		letter-spacing: -0.05rem;
		color: #0e2f47;
	}

	.chamada-informacoes button, .botao-enviar {
		background-color: #ffcb00;
		border-style: none;
		color: #fff;
		padding: 0.25rem 2rem;
		font-family: 'CamptonBold';
		font-size: 1.2rem;
		border-radius: 1rem;
		margin-top: 1.8rem;
		cursor: pointer;
	}

.titulo-form {
	color: #0e2f47;
	font-size: 1.4rem;
	letter-spacing: -0.05rem;
	text-align: center;
	margin-bottom: 1rem;
}

label {
	font-family: 'CamptonBook';
	font-size: 0.7rem;
}

input[type=text] {
	margin: 0.8rem 0;
	display: block;
	border: 1.8px solid #7e8d99;
	border-radius: 0.3rem;
}

input[type=file] {
	width: 100%;
	background-color: transparent;
}

.botao-upload-file {
	position: relative;
	padding: 0.25rem 1rem;
	font-family: 'CamptonBold';
	font-size: 1.2rem;
	border-radius: 1.2rem;
	border: 1px solid #000;
	width: 60%;
	margin-left: 15%;
	text-align: center;
	margin-top: 1rem;
	cursor: pointer;
}

	.botao-upload-file input {
		width: 60%;
		margin-left: 15%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}

.botao-enviar {
	width: 75%;
	margin-left: 15%;
}

@media screen and (min-width: 1024px) {
	.container-inscrevase {
		flex-direction: row;
		align-items: flex-start;
		padding: 2vw 2vw;
	}

	.container-informacoes {
		width: 50%;
	}

	.container-form {
		width: 50%;
	}

	.chamada-informacoes {
		width: 80%;
		text-align: left;
	}

		.chamada-informacoes h3 {
			font-size: 2rem;
		}

		.chamada-informacoes button, .botao-enviar {
			font-size: 1.5rem;
			border-radius: 1.2rem;
		}

	.titulo-form {
		font-size: 2rem;
		text-align: left;
	}

		.titulo-form br {
			display: none;
		}

	label {
		font-size: 1rem;
	}

	input[type=text] {
		width: 92.5%;
	}

	.container-botoes {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.botao-upload-file {
		width: 40%;
		margin-left: 0%;
	}

		.botao-upload-file input {
			width: 100%;
			margin-left: 0%;
			margin-top: 0;
		}

	.botao-enviar {
		width: 40%;
		margin-left: 0%;
		margin-top: 1rem;
	}

	.container-inscrevase {
		margin-bottom: 2rem;
	}
}
