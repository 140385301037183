.container-conteudo{
	background-color: rgba(63,91,112,.5);
	opacity: .5;
}
.pop-up {
	position: absolute;
	top: 25%;
	left: 50%;
	transform: translateX(-50%);
	padding: 1rem 1rem;
	box-sizing: border-box;
	width: 50%;
	z-index: 5;
	background-color: #fff;
	opacity: 1;
	border-radius: 1.25rem;
	
	font-size: 1.4rem;
	border: 1.8px solid #7e8d99;
}
.triangulo{
	width: 0; 
	height: 0; 
	border-left: 1.5rem solid transparent;
	border-right: 1.5rem solid transparent;
	border-top: 1.5rem solid #fff;
	position: absolute;
    bottom: -1.5rem;
    left: 48%;
}
fieldset{
	border: none;

	font-size: 1rem;
}
.container-range{
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 1rem;

	font-size: 1rem;
}
.titulo-range{
	display: block;

	text-align: left;
	font-size: 1.3rem;

	margin-left: 3rem;
}
.range{
	position: relative;

	width: 95%;
	height: .3rem;

	-webkit-appearance: none;
    appearance: none;
    outline: none;

    display: inline-block;
    
    border-radius: 5px;

    background-color: #e1e1e1;
}
.range::-webkit-slider-thumb{
	-webkit-appearance: none;
	border: none;
  
    width: 1.25rem;
    height: 1.25rem;

    border-radius: 50%;
    background-color: #ffcb00;
}
.range::-moz-range-thumb {
	width: 1.25rem;
    height: 1.25rem;

	border-radius: 50%;
    background-color: #ffcb00;

	border: none;
}
.range::-ms-range-thumb {
	width: 1.25rem;
    height: 1.25rem;

	border-radius: 50%;
    background-color: #ffcb00;
	border: none;
}
.container-nota{
	color: #ffcb00;

	display: flex;
	justify-content: space-between;

	margin-left: 3rem;
}
.btn-enviar{
	background-color: #ffcb00;

	font-family: 'CamptonBold';
	font-size: 1.3rem;
	color: #fff;

	border: none;
	border-radius: 1.3rem;

	padding-top: .1rem;
	padding-bottom: .2rem;

	width: 30%;
}
@media screen and (max-width: 1024px){
	.pop-up{
		top: 172%;

		width: 80%;

		padding: 2rem 1rem; 

		font-size: 1.1rem;
	}
	.triangulo{
	    left: 42%;
	}
	.container-range{
		font-size: 0.7rem;
	}
	.titulo-range{
		margin-left: 0rem;
		margin-bottom: .5rem;

		font-size: .9rem;
	}
	.titulo-range:nth-child(1){
		margin-top: .5rem;
	}
	.range{
		width: 85%;
		height: .2rem;
	}
	.range::-webkit-slider-thumb{  
	    width: .8rem;
	    height: .8rem;
	}
	.range::-moz-range-thumb {
		width: .8rem;
	    height: .8rem;
	}
	.range::-ms-range-thumb {
		width: .8rem;
	    height: .8rem;
	}
	.container-nota{
		margin-left: 0rem;
	}
	.btn-enviar{
		font-size: 1rem;

		border-radius: 1rem;

		padding-top: .1rem;
		padding-bottom: .2rem;

		width: 70%;

		margin-top: 1rem;
	}
	.swiper-slide{
		background-color: transparent;
	}
	.container-seta{
		background-color: transparent;
	}
	.container-seta-direita, .container-seta-esquerda{
		background: linear-gradient(90deg, transparent 30%, transparent 100%);
	}
}