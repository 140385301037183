.categorias {
	overflow-x: hidden;
}

.categorias-titulo {
	font-size: 1.1rem;
	text-align: center;
	padding: 32px 16px;
	color: #103148;
}

.container {
	width: 85vw;
	margin-left: 7.5vw;
	padding: 5vw 0vw;
	box-sizing: border-box;
}

.container-icons-diferenciais {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/*overflow-x: hidden;*/
	height: 30vh;
	padding: 0vw;
}

/*.swiper{
	position: relative;
	left: -10vw;
	width: auto;
	height: 100%;
	display: flex;
	overflow: hidden;
}*/
.icon-diferenciais {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 5vw;
	margin-left: 5vw;
	cursor: pointer;
}

.moldura-icon {
	width: 12vmax;
	height: 12vmax;
	border-radius: 100%;
	box-shadow: 0 0 1.5em rgba(255, 216, 63, .8);
	display: flex;
	justify-content: center;
	align-items: center;
}

	.moldura-icon img {
		width: 70%;
	}

.icon-diferenciais span {
	margin-top: 0.8rem;
	text-transform: uppercase;
	font-size: 0.8rem;
	text-align: center;
	color: #ffda4b;
}

.swiper-slide-active span {
	color: #3f3f3f;
}

.swiper-slide-active .moldura-icon {
	box-shadow: 0 0 1.5em rgba(0, 0, 0, .15);
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-seta {
	position: absolute;
	background: rgb(255,255,255);
	height: 25vh;
	width: 30vw;
	z-index: 2;
}

.container-seta-direita {
	right: 0vw;
	background: linear-gradient(270deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
}

.container-seta-esquerda {
	left: 0vw;
	background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
}

.seta {
	position: absolute;
	z-index: 2;
}

.seta-esquerda {
	left: 7.5vw;
	transform: rotate(-180deg);
}

.seta-direita {
	right: 7.5vw;
}

.seta img {
	width: 5vw;
}

.categoria-selecionado {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}

.categoria-selecionado-PI {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}

.categoria-selecionado-CS {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}

.categoria-selecionado-SS {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}

.categoria-selecionado-IC {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}

.categoria-selecionado-PG {
	display: flex;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 10vw;
}



.container-categoria {
	background-color: #e1e1e1;
	border-radius: 1rem;
	/*margin-top: 1.5rem;*/
	display: flex;
	flex-direction: column;
}

.container-categoria-imagens {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 95%;
}

.categoria-imagens {
	background-color: #d4d4d4;
	width: 90%;
	height: 40vw;
	margin-left: 5%;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

	.categoria-imagens:nth-of-type(1) {
		margin-bottom: 1rem;
	}

.container-categoria-texto {
	width: 100%;
	padding: 32px 32px;
	box-sizing: border-box;
	text-align: center;
}

	.container-categoria-texto h2 {
		font-size: 1.1rem;
		color: #0e2f47;
		font-weight: bold;
	}

	.container-categoria-texto p {
		font-size: 1.1rem;
		color: #2f2f2f;
		font-family: 'CamptonBook';
		margin-top: 1rem;
	}

@media screen and (max-width: 1023px) {
	.swiper-desktop {
		display: none;
	}
}

@media screen and (min-width: 1024px) {
	.categorias-titulo {
		font-size: 2rem;
	}

	.container {
		width: 70vw;
		margin-left: 15vw;
		padding: 2.5vw 0;
	}

	.container-seta {
		display: none;
	}

	.seta {
		display: none;
	}

	.moldura-icon {
		width: 8vmax;
		height: 8vmax;
		border-radius: 8vmax;
	}

	.swiper-mobile {
		display: none;
	}

	.icon-diferenciais {
		margin-right: 2vw;
		margin-left: 2vw;
	}

	.container-categoria {
		flex-direction: row;
		margin-bottom: 2rem;
		/*padding-bottom: 0vw; */
	}

	.categoria-imagens {
		height: 20vw;
	}

		.categoria-imagens:nth-of-type(1) {
			margin-bottom: 2rem;
		}

	.container-categoria-texto {
		text-align: left;
	}

		.container-categoria-texto h2 {
			font-size: 1.8rem;
		}
/*
	.categoria-selecionado {
		width: 5vw;
		transform: translateX(0%);
		left: 75vw;
	}
	*/

	.categoria-selecionado-PI {
		width: 5vw;
		transform: translateX(0%);
		left: 19vw;
	}

	.categoria-selecionado-CS {
		width: 5vw;
		transform: translateX(0%);
		left: 34vw;
	}

	.categoria-selecionado-SS {
		width: 5vw;
		transform: translateX(0%);
		left: 47vw;
	}

	.categoria-selecionado-IC {
		width: 5vw;
		transform: translateX(0%);
		left: 60vw;
	}

	.categoria-selecionado-PG {
		width: 5vw;
		transform: translateX(0%);
		left: 75vw;
	}
}
.loading-container {
	position: absolute;
	z-index: 5;
	width: 100vw;
	min-height: 100vh;
	background-color: rgba(255, 255, 255, .9);
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	max-width: 200px;
}
